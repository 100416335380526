// src/components/SmartWebsites.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments,faBriefcase, faMicrophone, faUserCog,faChartLine,faRoute, faCartPlus, faHeadset, faBlog, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import tsiwsLogo from '../assets/tsiws-logo.png';
import evo from '../assets/evo.jpg';
import smart from '../assets/smart.jpg';
import { Link } from 'react-router-dom';
import '../styles/SmartWebsites.css';

function SmartWebsites() {
  return (
    <div className='smartWebsitePageContainer'>
      <h2 className='smartWebsiteHeader'>Smart Websites</h2>
  
      <img src={smart} alt="Tsiws Logo" className="smartWebsiteHeaderImg1" />
      <div className='smartWebsiteSubheaderDiv'>
       <p className='smartWebsiteSubHeaderOne'>What are they? <br /></p>
      <p className='smartWebsiteSubHeaderTwo'>Why do we need them? <br /></p>
      </div>
      <div className="smartWebsitesFadeInUponScrollCards">
        <p className='smartWebsiteCardHeader'>The Evolution of Websites</p>
        <img src={evo} alt="Tsiws Logo" className="smartWebsiteHeaderImg" />
 
        <p className='smartWebsiteCardDetails'>
        In today's Digital Age, Websites have Evolved beyond static pages. Smart Websites are Dynamic, Assistive, Interactive, and Engaging, Featuring Automation, Integrated Assistant Capabilities, Administrative Support Functionalities and more.         <br /> <br />
          <span id='smartWebsiteCardDetailsSpanIntro'>The following features are essential for modern businesses to excel in the digital age. They not only boost functionality but also enhance user engagement, streamline operations, and deliver personalized experiences, in addition to facilitating scalability, ease of use and 
          enhancing overall competitiveness</span>
        </p>
        <p className='smartWebsiteCardExpandedInfo'>Embracing these capabilities ensures continued relevance in today's digital world     </p>
      </div>


      <div className="smartWebsitesFadeInUponScrollCardsIntro">
        <p className='smartWebsiteCardHeaderIntro'>Key Features of a Smart Website</p>
          <p className='smartWebsiteCardExpandedInfo'>
   
        
        </p>
       </div>

      <div className="smartWebsitesFadeInUponScrollCards">
  <p className='smartWebsiteCardHeader'>In-built Customer Service Agent</p>
  <FontAwesomeIcon icon={faHeadset}   className="smartWebsitePageIcons" />
  <p className='smartWebsiteCardDetails'>
    The In-built Customer Service Agent feature provides real-time assistance to users visiting a website. This feature can answer questions, provide recommendations, and offer support, enhancing the overall user experience. <br /> <br />
    <span id='smartWebsiteCardDetailsSpanOne'>Example: <br />A user visits an e-commerce website and is unsure about which product to purchase. They can chat with the Customer Service Agent to get recommendations based on their preferences and needs. The agent can also promote products and act as a sales representative, guiding the user through the purchasing process.</span>
  </p>
  <p className='smartWebsiteCardExpandedInfo'>
    <span className='benefitsHeaderSpan'>Range of Benefits:</span> <br />
    1. Improved Customer Service: Provides instant assistance to users, enhancing their overall experience. <br /><br />
    2. Efficient Query Handling: Handles queries and support requests efficiently, reducing the workload on human customer service agents. <br /><br />
    3. 24/7 Availability: Available round the clock to assist users, even outside of regular business hours. <br /><br />
    4. Cost-effective: Reduces the need for hiring additional customer service staff, saving costs for the business. <br /><br />
    5. Product Promotion: The agent can promote products and offer deals, acting as a sales representative to increase conversions. <br /><br />
    6. Sales Assistance: Guides users through the purchasing process, helping to close sales and improve overall sales performance.
  </p>
  <Link to="/more-about-smart-websites">
    <button className="smartWebsiteDiveDeeperButton">Dive Deeper</button>
  </Link>
</div>


<div className="smartWebsitesFadeInUponScrollCards">
  <p className='smartWebsiteCardHeader'>In-built Administrative Assistant</p>
  <FontAwesomeIcon icon={faClipboardList} className="smartWebsitePageIcons"/>
  <p className='smartWebsiteCardDetails'>
    The In-built Administrative Assistant feature helps manage administrative tasks efficiently, streamlining operations for businesses. This feature can handle scheduling, manage emails, and automate routine tasks, freeing up valuable time for employees to focus on more critical activities. <br /> <br />
    <span id='smartWebsiteCardDetailsSpanOne'>Example: A user needs to schedule a meeting with a client. The Administrative Assistant can check the availability of all participants, suggest suitable time slots, send out invitations, and manage confirmations, ensuring a smooth and efficient scheduling process.</span>
  </p>
  <p className='smartWebsiteCardExpandedInfo'>
  <span className='benefitsHeaderSpan'>Range of Benefits:</span> <br />
  1. Time-saving: Automates routine tasks, freeing employees to focus on more critical work. <br /><br />
  2. Efficiency: Streamlines operations and reduces errors. <br /><br />
  3. Organization: Keeps schedules and emails well-organized and accessible. <br /><br />
  4. Cost-effective: Reduces the need for additional administrative staff. <br /><br />
  5. Improved Productivity: Boosts overall productivity by managing administrative tasks. <br /><br />
  6. Enhanced Communication: Ensures timely responses and efficient coordination.
</p>
<Link to="/more-about-smart-websites">
    <button className="smartWebsiteDiveDeeperButton">Dive Deeper</button>
  </Link>
</div>


      <div className="smartWebsitesFadeInUponScrollCards">
  <p className='smartWebsiteCardHeader'>Personalized, User-specific Content</p>
  <FontAwesomeIcon icon={faUserCog} className="smartWebsitePageIcons" />
  <p className='smartWebsiteCardDetails'>
    The Personalized, User-specific Content feature enhances both e-commerce and customer service scenarios. For e-commerce, it customizes the website experience based on user preferences, showing personalized product recommendations and relevant content. In customer service, it enables agents to generate images or diagrams tailored to the user's query, aiding in problem resolution. This feature also includes addressing users by name, creating a more personalized and engaging experience. <br /> <br />
    <span id='smartWebsiteCardDetailsSpanOne'>Example: A user named Sarah visits an e-commerce website and is greeted with a personalized homepage featuring product recommendations based on her past purchases and browsing history. As Sarah explores the site, she encounters tailored content that matches her interests and preferences. If she has a question about a product, she can chat with a customer service agent who can provide personalized assistance. For instance, if Sarah needs help setting up a new device, the agent can generate an image or diagram illustrating the setup process, making it easier for Sarah to follow along. The website also addresses Sarah by name throughout her visit, creating a more personalized and engaging experience. This personalized approach enhances Sarah's user experience and increases the likelihood of a successful purchase or issue resolution.</span>
  </p>
  <p className='smartWebsiteCardExpandedInfo'>
    <span className='benefitsHeaderSpan'>Range of Benefits:</span> <br />
    1. Personalization: Customizing the user experience based on individual preferences and addressing users by name creates a sense of personalization and makes users feel valued. <br /><br />
    2. Engagement: Personalized interactions can increase user engagement, encouraging users to spend more time on the website. <br /><br />
    3. Retention: A personalized experience can help retain users by making them feel more connected to the website. <br /><br />
    4. Brand Loyalty: Users are more likely to develop loyalty towards a brand that offers personalized experiences. <br /><br />
    5. Efficiency: Tailored assistance in customer service scenarios, such as generating images or diagrams, can help resolve issues more efficiently, improving customer satisfaction. <br />
  </p>
  <Link to="/more-about-smart-websites">
    <button className="smartWebsiteDiveDeeperButton">Dive Deeper</button>
  </Link>
</div>



<div className="smartWebsitesFadeInUponScrollCards">
  <p className='smartWebsiteCardHeader'>Automatic Blog Generator</p>

  <FontAwesomeIcon icon={faBlog} className="smartWebsitePageIcons"/>

  <p className='smartWebsiteCardDetails'>
    The Automatic Blog Generator feature uses AI to create blog posts based on predefined topics, keywords, and user input. This tool helps businesses maintain an active blog with minimal effort, ensuring consistent content creation and engagement with their audience. <br /> <br />
    <span id='smartWebsiteCardDetailsSpanOne'>Example: A business owner wants to maintain a weekly blog on their website but doesn't have the time to write each post manually. They use the Automatic Blog Generator to create content. The owner provides the topic, keywords, and any specific points they want to include. The AI then generates a well-structured blog post, complete with relevant images and formatting. The owner can review, edit, and publish the post directly to the website. This streamlined process ensures regular updates to the blog, keeping the audience engaged and improving SEO.</span>
  </p>
  <p className='smartWebsiteCardExpandedInfo'>
    <span className='benefitsHeaderSpan'>Range of Benefits:</span> <br />
    1. Consistent Content Creation: Ensures the blog is regularly updated with fresh content, keeping the audience engaged and improving SEO. <br /><br />
    2. Time-saving: Reduces the time and effort required to create blog posts manually, allowing business owners to focus on other tasks. <br /><br />
    3. SEO Optimization: Generates content optimized for search engines, helping to improve the website's search engine rankings. <br /><br />
    4. Customization: Allows users to provide specific topics, keywords, and points to tailor the generated content to their needs. <br /><br />
    5. Enhanced Engagement: Regularly updated blogs can attract more visitors, encourage repeat visits, and foster a loyal audience. <br />
  </p>
  <Link to="/more-about-smart-websites">
    <button className="smartWebsiteDiveDeeperButton">Dive Deeper</button>
  </Link>
</div>


      
      <div className="smartWebsitesFadeInUponScrollCards">
  <p className='smartWebsiteCardHeader'>E-commerce Capabilities</p>
  <FontAwesomeIcon icon={faCartPlus} className="smartWebsitePageIcons" />
  <p className='smartWebsiteCardDetails'>
    In Jamaica, where the greater percentage of websites does not have e-commerce capabilities, the digital era is evolving rapidly, marked by the introduction of a national digital currency. This shift indicates a growing need for businesses to adopt e-commerce capabilities to facilitate online transactions and stay competitive in the digital landscape. <br /> <br />
    <span id='smartWebsiteCardDetailsSpanOne'>Example: A local Jamaican business, previously operating solely through a physical store, decides to expand its reach by launching an e-commerce website. With the introduction and adoption of the national digital currency, the business sees an opportunity to offer online sales and payment options to customers across the country. By implementing e-commerce capabilities, the business can now accept digital currency payments, providing a convenient and secure shopping experience for customers.</span>
  </p>
  <p className='smartWebsiteCardExpandedInfo'>
    <span className='benefitsHeaderSpan'>Range of Benefits:</span> <br />
    1. Increased Reach: E-commerce capabilities allow businesses to reach a wider audience beyond their physical location, potentially leading to an increase in sales and revenue. <br /><br />
    2. Convenience: Customers can shop online from the comfort of their homes, at any time, using the national digital currency, making transactions quick and hassle-free. <br /><br />
    3. Competitive Advantage: Businesses that adopt e-commerce early on can gain a competitive edge in the market by meeting the evolving needs of digitally savvy customers. <br /><br />
    4. Adaptability: In a rapidly changing digital landscape, businesses that embrace e-commerce are better positioned to adapt to future technological advancements and customer preferences. <br />
  </p>
  <Link to="/more-about-smart-websites">
    <button className="smartWebsiteDiveDeeperButton">Dive Deeper</button>
  </Link>
</div>



      <div className="smartWebsitesFadeInUponScrollCards">
        <p className='smartWebsiteCardHeader'> Voice / Text interaction capabilities</p>
        <FontAwesomeIcon icon={faMicrophone} className="smartWebsitePageIcons"/> 
        <FontAwesomeIcon icon={faComments} className="smartWebsitePageIcons"/>  
     
            
            
        <p className='smartWebsiteCardDetails'>Voice and text interaction capabilities enable users to interact with a website using human language.   <br /> <br />
   
          <span id='smartWebsiteCardDetailsSpanOne'>This allows users to interact with the website even while engaged in other activities, providing a hands-free, intuitive, and seamless browsing experience. Whether users are cooking, driving, or performing any other task, they can easily communicate with the website using voice commands. This feature also enhances ease of use by offering an alternative to traditional navigation methods, making the website more user-friendly and accessible to a broader audience.</span>
        </p>
        <p className='smartWebsiteCardExpandedInfo'>
    <span className='benefitsHeaderSpan'>Range of Benefits:</span> <br />
    1. Accessibility: Voice and text interaction capabilities make the website accessible to users with disabilities or those who prefer alternative input methods. <br /> <br />
    2. Convenience: Users can interact with the website hands-free, making it easier to multitask or use the website while on the go. <br /> <br />
    3. Personalization: By analyzing user input, the website can provide more personalized responses and recommendations tailored to the user's preferences. <br /> <br />
    4. Engagement: Voice interactions can make the user experience more engaging and interactive, leading to increased user satisfaction and retention. <br /> <br />
    5. Efficiency: Voice commands can be faster than typing, allowing users to quickly navigate the website and find the information they need. <br />
  </p>
  <Link to="/more-about-smart-websites">
    <button className="smartWebsiteDiveDeeperButton">Dive Deeper</button>
  </Link>
</div>
    
      <div className="smartWebsitesFadeInUponScrollCards">
  <p className='smartWebsiteCardHeader'>Local AI-powered Analytics</p>

  <FontAwesomeIcon icon={faChartLine} className="smartWebsitePageIcons"/>

  <p className='smartWebsiteCardDetails'>
  Analytics is a powerfull tool used to analyze user behavior, trends, and patterns on a website.<br /> <br />
    <span id='smartWebsiteCardDetailsSpanOne'>In-built, advanced AI-powered analytics dashboard that offers comparable capabilities to Google Analytics, with additional tools and customization. </span>
  </p>
  <p className='smartWebsiteCardExpandedInfo'>
    <span className='benefitsHeaderSpan' >Range of Benefits:</span> <br />
    1. Enhanced Insights: AI-powered analytics can provide deeper insights into user behavior and trends than traditional analytics tools, allowing businesses to make more informed decisions. <br /><br />
    2. Predictive Analytics: AI algorithms can predict future user actions based on past behavior, helping businesses anticipate trends and optimize their strategies accordingly. <br /><br />
    3. Automation: AI-powered analytics can automate the process of data analysis, saving time and resources for businesses. <br />
    4. Personalization: By analyzing user data, AI-powered analytics can help businesses deliver personalized experiences to users, improving engagement and conversion rates. <br /><br />
    5. Competitive Advantage: Businesses that leverage AI-powered analytics can gain a competitive edge by being more responsive to user needs and market trends. <br /><br />
      </p>
      <Link to="/more-about-smart-websites">
    <button className="smartWebsiteDiveDeeperButton">Dive Deeper</button>
  </Link>
</div>


      <div className="smartWebsitesFadeInUponScrollCards">
  <p className='smartWebsiteCardHeader'>Quick Navigation </p>
  <FontAwesomeIcon icon={faRoute}className="smartWebsitePageIcons" />
  <p className='smartWebsiteCardDetails'>
    A user visits a website and verbally requests information about a product, service, or other content. The website then instantly and smoothly navigates -to the associated page. <br /> <br />
    <span id='smartWebsiteCardDetailsSpanOne'>Example: A user visits a dog breeder website and says, "I want to see the Pitbulls that you have." <br />
    Smart Website: "Sure, here is the page with images and information about our Pitbulls."</span>
  </p>
  <p className='smartWebsiteCardExpandedInfo'>
    <span className='benefitsHeaderSpan' >Range of Benefits:</span> <br />
    1. Enhanced User Experience: Users can quickly navigate to desired pages, eliminating the need to manually hunt or search, improving overall satisfaction. <br /><br />
    2. Efficient Information Retrieval: Reduces the time and effort required for users to find specific content on the website. <br /><br />
    3. Accessibility: Enables users with disabilities or limited mobility to navigate the website easily using voice commands. <br /><br />
    4. Intuitive Interaction: Mimics natural conversation, making the website more user-friendly and engaging. <br /><br />
    5. Time-saving: Streamlines the browsing experience by eliminating the need for manual navigation, leading to quicker access to information.
  </p>
  <Link to="/more-about-smart-websites">
    <button className="smartWebsiteDiveDeeperButton">Dive Deeper</button>
  </Link>
</div>




      <div className="smartWebsitesFadeInUponScrollCards">
        <p className='smartWebsiteCardHeader'>Why You Need One</p>
        <FontAwesomeIcon icon={faBriefcase}  className="smartWebsitePageIcons"/>
        <p className='smartWebsiteCardDetails'>
          A smart website isn't just a digital presence; it's a powerful tool for growth.
          <br /> <br />
          <span id='smartWebsiteCardDetailsSpanOne'> In the fast-paced digital age, the need for businesses to adapt and thrive has never been more critical. Enter smart websites – the next evolution in digital presence that goes beyond static pages to deliver dynamic, assistive, and interactive experiences.<br /><br /> These websites are not just about aesthetics; they are powerful tools that drive engagement, streamline operations, and deliver personalized experiences. The evolving digital landscape has created a new demand that exceeds the capabilities of traditional websites, a smart websites has meet this need.

The incorporation of automation, integrated assistant capabilities, and administrative support functionalities streamlines operations, freeing up valuable time and resources. <br /><br />This efficiency translates to improved customer service, efficient query handling, and 24/7 availability, ensuring businesses are always at the forefront of customer interactions.<br /><br /> Imagine a website that not only showcases products but also assists customers in real-time, guiding them through the purchasing process with personalized recommendations. Picture a website that manages administrative tasks seamlessly, freeing up valuable time for employees to focus on innovation and growth. These are the capabilities of a smart website.</span>  </p>
        <p className='smartWebsiteCardExpandedInfo'>
        Smart websites are not just a digital presence; they are a powerful tool for growth and innovation. They transform the way businesses interact with customers, streamline operations, and pave the way for future success. In today's digital age, a smart website is not just an option; it's a necessity.</p>
      </div>

      <div className="smartWebsitesFadeInUponScrollCards">
      <img src={tsiwsLogo} alt="Tsiws Logo" className="contactLogoChatbot" />

        <p className='smartWebsiteCardHeader'>Future-Proof Your Business</p>
        <p className='smartWebsiteCardDetails'>
          As technology continues to advance, so should your website.
          <br /> <br />
          <span id='smartWebsiteCardDetailsSpanOne'>A smart website ensures your business stays ahead of the curve.</span>
        </p>
        <p className='smartWebsiteCardExpandedInfo'>
          By incorporating the latest digital innovations, smart websites can adapt to emerging trends and technologies, providing your business with a competitive edge.<br /><br /> Future-proofing your website means future-proofing your business.
        </p>
      </div>


      <div className="smartWebsitesFadeInUponScrollCards">
      
        <div className="smartWebsiteButtonsContainer">
        <Link to="/more-about-smart-websites" className="smartWebsiteButtons">More about Smart Websites</Link>
        <Link to="/contact" className="smartWebsiteButtons">Speak with Tsiws</Link>
        <Link to="/" className="smartWebsiteButtons">Home</Link>
      
      </div>
      </div>

    </div>
  );
}

export default SmartWebsites;
