import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faEnvelope, faPhone, faComment } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faTwitter, faInstagram, faTiktok, faFacebook, faYoutube} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

import tsiwsLogo from '../assets/tsiws-logo.png';
import smartWebsite from '../assets/smartWebsite.jpg';
import api from '../assets/api.jpg';
import aiSystems from '../assets/aiSystems.jpg';
import aiAvatar from '../assets/aiAvatar.jpg';
import mobileApp from '../assets/mobileApp.jpg';
import taskAutomation from '../assets/taskAutomation.jpg';
import technologyConsultation from '../assets/technologyConsultation.jpg';
import '../styles/Home.css';

function Home() {

  

  const cardsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
          }
        });
      },
      { threshold: 0.4 } // Adjust threshold to a lower value
    );
  
    cardsRef.current.forEach((card) => {
      observer.observe(card);
    });
  
    return () => observer.disconnect();
  }, []);
  return (
    <div className="home-container">
      <img src={tsiwsLogo} alt="Tsiws Logo" className="home-logo" />
      <h1 className="home-heading">
        <span className="home-welcome">Welcome to</span>
<span className="home-brand">Tsiws</span>
      </h1>
      <h2 className="subHeader">Technology Solutions Provider</h2>
      <p className='subSubHeader'>Tsiws is a Jamaica based, <span className='subSubHeaderSpanOne'>AI Native</span> Tech Agency<br /> <br />Developing AI-Centric Products and Services across Industries for Establishments with the Need to Harness and Incoperate Advanced Capabilities into their Operations and Infrastructure.</p>
      <div className='intro-section'>
    <p className='intro'>-- Start Here --</p>

    <div ref={(el) => (cardsRef.current[0] = el)} className="slideInFromLeftIntroCards">
  <p className='cardHeader'>Smart Website Development</p>
  <img src={smartWebsite} alt="Smart Website" className="introCardImageOne" />
  <p className='introCardDetails'>Your Business is More than just a Building <br /> <br /><span id='introCardDetailsSpanOne'> Your Website should be More than just an Online Sign</span></p>
  <p className='introCardDetailsCallToActionText'>Enhance your Online Environment <br /> With Advanced Technology</p> 
  <Link to="/smart-websites">
    <button className="navigate-button">Explore Features</button>
  </Link>
</div>



  <div ref={(el) => (cardsRef.current[0] = el)} className="slideInFromLeftIntroCards">
    <p className='cardHeader'>API Development</p>
    <img src={api} alt="API Development" className="introCardImageOne" />

    <p className='introCardDetails'>Your Business Needs Seamless Connectivity <br /> <br /><span id='introCardDetailsSpanOne'> We Are Building Modern Bridges Between Applications</span></p>

    <p className='introCardDetailsCallToActionText'>Unlock New Capabilities with Custom APIs</p> 
    <Link to="/api-development">
    <button className="navigate-button">Learn More</button>
  </Link>
</div>
   
   
   
   
   
<div ref={(el) => (cardsRef.current[0] = el)} className="slideInFromLeftIntroCards">
      <p className='cardHeader'>Technology Consultation</p>
 
    
      <img src={technologyConsultation} alt="API Development" className="introCardImageOne" />

    <p className='introCardDetails'>The Conversations that You need To have when Navigating Today's Digital Landscape <br /> <br /><span id='introCardDetailsSpanOne'> You need Expert Advice for Cutting-Edge Solutions</span></p>

    <p className='introCardDetailsCallToActionText'>Get Strategic Insights for Technological Stability</p> 
    <Link to="/technology-consultation">
    <button className="navigate-button">Get More Info</button>
  </Link>
    </div>
    
    <div ref={(el) => (cardsRef.current[0] = el)} className="slideInFromLeftIntroCards">
      <p className='cardHeader'>Ai Systems Integration</p>
 
      <img src={aiSystems} alt="API Development" className="introCardImageOne" />

    <p className='introCardDetails'> Harness the Power of AI to Transform Your Enterprise<br /> <br /><span id='introCardDetailsSpanOne'>You need Seamless AI Integration for Smooth and Smarter Operations</span></p>

    <p className='introCardDetailsCallToActionText'>You should Integrate AI at Every Practical Level</p> 
    <Link to="/ai-integration">
    <button className="navigate-button">Discover How</button>
  </Link>
    </div>




    <div ref={(el) => (cardsRef.current[0] = el)} className="slideInFromLeftIntroCards">
    <p className='cardHeader'>Operations Task Automation</p>
  
    <img src={taskAutomation} alt="API Development" className="introCardImageOne" />

    <p className='introCardDetails'>  Now, Businesses are Enhancing Productivity with Smart Automation Solutions<br /> <br /><span id='introCardDetailsSpanOne'>Streamline Your Processes, Maximize Efficiency</span></p>

    <p className='introCardDetailsCallToActionText'>Automate, Innovate, Elevate Your Business</p> 
    <Link to="/task-automation">
    <button className="navigate-button">See Examples</button>
  </Link>
    </div>
    
    
    
    
    
    
    
  
    
    
        
    <div ref={(el) => (cardsRef.current[0] = el)} className="slideInFromLeftIntroCards">
      <p className='cardHeader'>Mobile App Development</p>
      
    
      <img src={mobileApp} alt="API Development" className="introCardImageOne" />

    <p className='introCardDetails'>Bring Your Best Visions to Life, One App at a Time <br /> <br /><span id='introCardDetailsSpanOne'> Your Ideas, Our Expertise: Seamless App Development</span></p>

    <p className='introCardDetailsCallToActionText'>Your Path to Effective Mobile Applications</p> 
    <Link to="/app-development">
    <button className="navigate-button">Get Started</button>
  </Link>
    </div>
    
    
    
    

    
    
    
    
    <div ref={(el) => (cardsRef.current[0] = el)} className="slideInFromLeftIntroCards">
      <p className='cardHeader'>Ai Avatar Development</p>
      <img src={aiAvatar} alt="API Development" className="introCardImageOne" />

    <p className='introCardDetails'>The Importance and Relevance of Ai Avatars in business and in personal life is quickly becoming Apparent.<br /><br /><span id='introCardDetailsSpanOne'>The Great ones are Limited</span></p>

    <p className='introCardDetailsCallToActionText'>Ready for Your Virtual Assistant?</p> 
    <Link to="/ai-avatar">
    <button className="navigate-button">Learn More</button>
  </Link>
    </div>
    
    
    
    
    <div ref={(el) => (cardsRef.current[0] = el)} className="slideInFromLeftIntroCards">
      <div className=' slideInFromLeftIntroCardsInnerDiv '>
      <p className='cardHeaderContact'>Speak With Us</p>
      
          <p className='introCardDetailsContact'>Click our floating logo for Quick Responses   </p>  
          <img src={tsiwsLogo} alt="Tsiws Logo" className="contactLogoChatbot" />


          <div className="contact-section">
  <p className="introCardDetailsContact">Message, Call or Email Us</p>
  <div className="contact-icons">
    <a href="https://wa.me/8765338692" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faWhatsapp} className="card-icon" />
    </a>
    <a href="tel:+18765338692">
      <FontAwesomeIcon icon={faPhone} className="card-icon" />
    </a>
    <a href="mailto:tsiws.official@gmail.com">
      <FontAwesomeIcon icon={faEnvelope} className="card-icon" />
    </a>
  </div>
  
  <p className="introCardDetailsContact"></p>
  <p className="introCardDetailsContact">
    <span id="introCardDetailsSpanOneContact">There are Huge Benefits for you over on our Social Media</span>
  </p>
  
  <div className="social-icons">
    <a href="https://x.com/tsiws_official" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faTwitter} className="social-icon" />
    </a>
    <a href="https://instagram.com/tsiws_official" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faInstagram} className="social-icon" />
    </a>
    <a href="https://tiktok.com/@tsiws_official" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faTiktok} className="social-icon" />
    </a>
    <a href="https://facebook.com/tsiws" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faFacebook} className="social-icon" />
    </a>
    <a href="https://youtube.com/@tsiws" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faYoutube} className="social-icon" />
    </a>
  </div>
</div>


 </div>
    
   
    </div>
   
   
   
    <div ref={(el) => (cardsRef.current[0] = el)} className="slideInFromLeftIntroCards">
      
      
      
      <p className='cardHeader'>About Tsiws</p>
      <img src={tsiwsLogo} alt="Tsiws Logo" className="aboutUsLogo" />

    <p className='introCardDetails-about'>Welcome to Tsiws, where we tailor custom technology solutions to meet your business or personal unique needs.<br /><br /> Formally known as Island Website Solutions and Based in Jamaica, we are an emerging trendsetter at the forefront of the modern technology landscape.<br /><br />

Our vision and innovation, our advanced capability and flexibility are the things that sets us apart in our field as an Ai First technology solutions provider <br /><br />  <br /></p>

  
   
    </div>
    
    
    






    <div className="aiIntegrationFadeInUponScrollCardsInfo">
  <p className='aiIntegrationCardHeader'>T s i w s</p>
  <p className='homeFootExpandedInfo'>
    At Tsiws, we are dedicated to driving innovation and excellence in technology solutions. Our commitment to integrating advanced AI capabilities into diverse industries ensures that your business remains at the forefront of technological advancements. Partner with us to transform your operations, enhance productivity, and unlock new potentials. Explore our wide range of services, from smart website development to AI avatar creation, and let us help you achieve your digital aspirations.
  </p>
</div>


    
    
    </div>
    </div>
  );
}

export default Home;








