import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import '../styles/ChatInterface.css';


const ChatInterface = ({ onClose }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecognizing, setIsRecognizing] = useState(false);
  const chatBoxRef = useRef(null);
  const inputRef = useRef(null);
  const recognitionRef = useRef(null);

  const generateSessionId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  const handleSubmit = useCallback(async (textMessage) => {
    if (!textMessage.trim()) return;

    const sessionId = localStorage.getItem('sessionId') || generateSessionId();
    if (!localStorage.getItem('sessionId')) {
      localStorage.setItem('sessionId', sessionId);
    }

    const newMessages = [...messages, { role: 'user', content: textMessage }];
    setMessages(newMessages);
    setMessage('');
    setIsLoading(true);

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      const fullUrl = `${apiUrl}/api/chat`;
      console.log('Sending message to:', fullUrl);
      const res = await axios.post(fullUrl, {
        messages: [{ role: 'user', content: textMessage }],
        sessionId: sessionId,
      });
      const botMessage = res.data.answer;

      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'bot', content: botMessage }
      ]);

      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(botMessage);
        window.speechSynthesis.speak(utterance);
      } else {
        console.warn('Web Speech API is not supported in this browser.');
      }
    } catch (error) {
      console.error('Error sending message to server:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'bot', content: 'A connection error occurred. Please try again.' },
      ]);
    } finally {
      setIsLoading(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [messages]);

  useEffect(() => {
    if (!localStorage.getItem('sessionId')) {
      localStorage.setItem('sessionId', generateSessionId());
    }

    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = 'en-US';

      recognition.onresult = (event) => {
        const spokenMessage = event.results[0][0].transcript;
        setMessage(spokenMessage);
        handleSubmit(spokenMessage);
        setIsRecognizing(false);
        const inputContainer = document.querySelector('.input-container');
        if (inputContainer) {
          inputContainer.classList.remove('activated');
        }
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsRecognizing(false);
        const inputContainer = document.querySelector('.input-container');
        if (inputContainer) {
          inputContainer.classList.remove('activated');
        }
      };

      recognition.onend = () => {
        setIsRecognizing(false);
        const inputContainer = document.querySelector('.input-container');
        if (inputContainer) {
          inputContainer.classList.remove('activated');
        }
      };

      recognitionRef.current = recognition;
    } else {
      alert('Sorry, your browser does not support Speech Recognition.');
    }
  }, [handleSubmit]);

  const handleSpeechInput = () => {
    if (recognitionRef.current && !isRecognizing) {
      recognitionRef.current.start();
      setIsRecognizing(true);
      const inputContainer = document.querySelector('.input-container');
      if (inputContainer) {
        inputContainer.classList.add('activated');
      }
    } else {
      const inputContainer = document.querySelector('.input-container');
      if (inputContainer) {
        inputContainer.classList.remove('activated');
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(message);
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      const chatBox = chatBoxRef.current;
      const lastMessage = chatBox.lastElementChild;
      if (lastMessage) {
        const scrollOffset = lastMessage.offsetTop - chatBox.clientHeight / 1;
        chatBox.scrollTop = scrollOffset;
      }
    }
  }, [messages]);

  return (
    <div className='App'>
      <div className="chat-container" style={{left: '50%', transform: 'translateX(-50%)'}}>
        <div id="chat-box" ref={chatBoxRef} className="collapsed">
          <button className="close-btn" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {messages.map((msg, index) => (
            <div key={index} className={`chat-message ${msg.role === 'user' ? 'user-message' : 'bot-message'}`}>
              {msg.content}
            </div>
          ))}
        </div>
      </div>
      <div className="input-container">
        <button
          id="mic-btn"
          type="button"
          onClick={handleSpeechInput}
        >
          🎤 {isRecognizing && <span>Listening...</span>}
        </button>
        <input
          type="text"
          id="user-input"
          value={message}
          ref={inputRef}
          onChange={(e) => setMessage(e.target.value)}
          onFocus={() => chatBoxRef.current?.classList.add('expanded')}
          onBlur={() => {
            setTimeout(() => {
              if (chatBoxRef.current && !document.activeElement.isEqualNode(inputRef.current)) {
                chatBoxRef.current.classList.remove('expanded');
              }
            }, 100);
          }}
          onKeyPress={handleKeyPress}
          placeholder="Tsiws Quick Response..."
        />
        <button
          id="send-btn"
          onClick={(e) => { e.preventDefault(); handleSubmit(message); }}
          onMouseDown={(e) => e.preventDefault()}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>

      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}

export default ChatInterface;
