// src/components/AIAvatar.js
import React from 'react';
import '../styles/AIAvatar.css';
import tsiwsLogo from '../assets/tsiws-logo.png';
import influ from '../assets/influ.jpg';
import avatarIntro from '../assets/avatarIntro.jpg';
import personalAvatar from '../assets/personalAvatar.jpg';
import educationalAvatar from '../assets/educationalAvatar.jpg';

import businessAvatar from '../assets/businessAvatar.jpg';
import { Link } from 'react-router-dom';

function AIAvatar() {
  return (

<div className="aiAvatarPageContainer">

<div className="aiAvatarApplicationIntroCard">
    <p className='aiAvatarHeader'>Introduction to AI Avatars</p>
    <img src={avatarIntro} alt="Avatar" className="aiAvatarHeaderImg" />
 



    <div className="apiDevelopmentFadeInUponScrollCardsCard">

    <p className='aiAvatarSubHeader'>
        AI Avatars - the forefront of digital innovation. <br /><br /> As technology evolves, AI avatars are transforming business operations, personal organization, audience engagement, and educational delivery.<br /> <br />Entities seek unique avatars to stand out in a crowded digital landscape, with many iconic designs already claimed.<br /><br /> These intelligent virtual assistants and influencers leverage AI to automate tasks, personalize interactions, and enhance engagement.<br /><br /> Explore how AI avatars are reshaping industries, driving efficiencies, and revolutionizing digital experiences.
    </p>
</div>
  
</div>





    <div className="aiAvatarApplicationCard">
        <p className='aiAvatarsubCardHeader'>Business Applications</p>
        <br />
        <img src={businessAvatar} alt="Coperate Avatar" className="aiAvatarImg" />
 
        <p>
            AI avatars streamline customer service operations, assist in sales and marketing, and optimize internal processes by automating tasks and supporting employees.
        </p>
    </div>
    <div className="aiAvatarApplicationCard">
    <p className='aiAvatarsubCardHeader'>Educational / Training Avatar</p>

    <br />
        <img src={educationalAvatar} alt="Coperate Avatar" className="aiAvatarImg" />
 
        <p>
            AI avatars create personalized learning experiences, tutor students, and facilitate immersive training simulations across various fields, enhancing education and skill development.
        </p>
    </div>

    <div className="aiAvatarApplicationCard">
    <p className='aiAvatarsubCardHeader'>Personal Avatar</p>
    <br />
        <img src={personalAvatar} alt="Personal Avatar" className="aiAvatarImg" />
 
        <p>
            AI avatars manage daily routines, provide reminders, and offer personalized recommendations, enhancing convenience and efficiency in personal tasks.
        </p>
    </div>

    <div className="aiAvatarApplicationCard">
    <p className='aiAvatarsubCardHeader'>Virtual influencers</p>
       <p>
        <br />
       <img src={influ} alt="Ai Influencer" className="aiAvatarImg" />
       <br /><br />
            Life-like AI-powered virtual influencers engage with followers on social media, promoting brands and products through targeted, interactive content and storytelling.
        </p>
    </div>

   
    <div className="aiAvatarApplicationCard">
   
    <img src={tsiwsLogo} alt="Tsiws Logo" className="consultationFooterLogo" />
 
    <p className='aiAvatarsubCardHeader'> Embrace the future of digital interaction with AI avatars.</p>
    <p className='aiAvatarExpandedInfo'>
        Whether enhancing customer engagement in business, personalizing learning experiences in education, or innovating marketing strategies through virtual influencers, AI avatars are reshaping industries worldwide. At Tsiws, we're dedicated to leveraging these advanced technologies to empower your business and personal endeavors. Explore the possibilities, harness the power of AI, and stay ahead in a rapidly evolving digital landscape.
    </p>
    <div className="smartWebsiteButtonsContainer">
        <Link to="/contact" className="smartWebsiteButtons">Speak with Tsiws</Link>
        <Link to="/" className="smartWebsiteButtons">Home</Link>
    </div>
</div>
</div>



  );
}

export default AIAvatar;
