// src/components/AppDevelopment.js
import React from 'react';
import tsiwsLogo from '../assets/tsiws-logo.png';

import app from '../assets/app.jpg';
import { Link } from 'react-router-dom';

import '../styles/AppDevelopment.css';

function AppDevelopment() {
  return (
    <div className='appDevelopmentPageContainer'>
      <h2 className='appDevelopmentHeader'>App Development</h2>
      
      <img src={app} alt="Technology Consultation" className="appDevImg" />
 
      <p className='appDevelopmentSubHeader'>Bringing Your Ideas to Life</p>







      <div className="appDevelopmentFadeInUponScrollCards">
    <p className='appDevelopmentCardHeader'>Introduction</p>
    <p className='appDevelopmentCardDetails'>
        Transform your ideas into powerful mobile solutions with Tsiws. 
        <br /><br /><span id='appDevelopmentCardDetailsSpanOne'>Innovative app development tailored to your business needs.</span> 
    </p>
    <p className='appDevelopmentCardExpandedInfo'>
        At Tsiws, we specialize in creating cutting-edge mobile applications that drive business growth and deliver exceptional user experiences. From concept to deployment and beyond, our team is dedicated to turning your vision into reality with expert development, user-centric design, and comprehensive support. Whether you're a startup looking to disrupt the market or an established enterprise seeking digital transformation, we're here to partner with you every step of the way.
    </p>
</div>










      <div className="appDevelopmentFadeInUponScrollCards">
    <p className='appDevelopmentCardHeader'>Innovative Solutions</p>
    <p className='appDevelopmentCardDetails'>
        Your ideas, our expertise: seamless app development.
        <br /><br /><span id='appDevelopmentCardDetailsSpanOne'>Transform your vision into reality.</span>
    </p>
    <p className='appDevelopmentCardExpandedInfo'>
        At Tsiws, we specialize in creating cutting-edge mobile applications that not only meet but exceed user expectations. By combining your innovative ideas with our technical expertise, we develop solutions that stand out in the competitive app market. Our approach focuses on leveraging the latest technologies and industry best practices to deliver apps that are intuitive, scalable, and future-proof. Whether you're launching a new app or enhancing an existing one, we are committed to transforming your vision into a reality that drives user engagement and business success.
    </p>
</div>


      <div className="appDevelopmentFadeInUponScrollCards">
    <p className='appDevelopmentCardHeader'>Expert Development</p>
    <p className='appDevelopmentCardDetails'>
        Bring your best visions to life, one app at a time.
        <br /><br /><span id='appDevelopmentCardDetailsSpanOne'>Leverage our expertise for effective mobile solutions.</span>
    </p>
    <p className='appDevelopmentCardExpandedInfo'>
        At Tsiws, our team of experts specializes in developing high-quality mobile applications tailored to your unique business needs. With years of experience and a deep understanding of mobile technologies, we bring your app ideas to fruition while ensuring they align perfectly with your strategic goals. Whether you're looking to launch a consumer-facing app, enterprise solution, or a niche mobile product, our development process focuses on scalability, performance, and innovation. Partner with us to leverage cutting-edge technologies and turn your vision into a successful mobile application that drives business growth and user satisfaction.
    </p>
</div>

      <div className="appDevelopmentFadeInUponScrollCards">
    <p className='appDevelopmentCardHeader'>User-Centric Design</p>
    <p className='appDevelopmentCardDetails'>
        Focus on user-friendly design for maximum impact.
        <br /><br /><span id='appDevelopmentCardDetailsSpanOne'>Enhance user engagement with intuitive interfaces.</span> 
    </p>
    <p className='appDevelopmentCardExpandedInfo'>
        At Tsiws, we prioritize user experience, ensuring your app is intuitive, engaging, and easy to use. Our user-centric design approach involves understanding your target audience, their preferences, and behaviors to create interfaces that not only meet but exceed their expectations. By incorporating intuitive navigation, appealing visuals, and seamless interactions, we enhance user engagement and satisfaction. Whether you're developing a mobile app, web app, or enterprise solution, our focus remains on delivering a compelling user experience that drives adoption and retention.
    </p>
</div>


<div className="appDevelopmentFadeInUponScrollCards">
    <p className='appDevelopmentCardHeader'>Comprehensive Support</p>
    <p className='appDevelopmentCardDetails'>
        From concept to deployment, we've got you covered.
        <br /><br /><span id='appDevelopmentCardDetailsSpanOne'>Support at every step of the development process.</span>
    </p>
    <p className='appDevelopmentCardExpandedInfo'>
        At Tsiws, we offer comprehensive support throughout your app development journey. Our team is dedicated to guiding you from the initial concept phase, through design and development, to deployment and ongoing maintenance. We ensure that your app meets industry standards, user expectations, and your business goals. Whether you need assistance with defining project requirements, refining user interfaces, integrating complex features, or optimizing performance, our tailored support services are designed to maximize your app's success in the market. Partner with us for a seamless app development experience backed by expert guidance and proactive support.
    </p>
</div>
<div className="apiDevelopmentFadeInUponScrollCardsCard">

<p className='apiDevelopmentCardExpandedInfoCard'>Let us build that App for you</p>

</div>
<div className="apiDevelopmentFadeInUponScrollCards">
<img src={tsiwsLogo} alt="Tsiws Logo" className="consultationFooterLogo" />
 
    <p className='apiDevelopmentCardExpandedInfo'>
        At Tsiws, we are dedicated to transforming your mobile app ideas into reality. Our team of experts excels in creating innovative and user-centric applications that drive engagement and deliver tangible business results. Whether you're launching a new app or enhancing an existing one, we offer end-to-end services from conceptualization to deployment, ensuring your app stands out in a competitive market.<br /><br />
        Partner with us to leverage our extensive experience in mobile app development and stay ahead in a dynamic digital landscape. Let us help you unlock the full potential of your app with cutting-edge solutions tailored to your unique business needs.<br /><br />
        Book your appointment or speak with our AI assistant today.
    </p>
       <div className="smartWebsiteButtonsContainer">
        <Link to="/contact" className="smartWebsiteButtons">Speak with Tsiws</Link>
        <Link to="/" className="smartWebsiteButtons">Home</Link>
    </div>
</div>


    </div>
  );
}

export default AppDevelopment;
