import React, { useEffect, useState } from 'react';
import tsiwsLogo from '../assets/tsiws-logo.png';
import '../styles/DialogBox.css';

const DialogBox = ({ onClick }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!visible) return null;

  return (
    <div className="dialog-box" onClick={onClick}>
     <img src={tsiwsLogo} alt="Tsiws Logo" className="logoChatIcon" />

      <p>Tsiws Ai</p>
    </div>
  );
};

export default DialogBox;
