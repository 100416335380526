// src/components/TechnologyConsultation.js
import React from 'react';
import '../styles/TechnologyConsultation.css';
import tsiwsLogo from '../assets/tsiws-logo.png';
import ITConsulting from '../assets/ITConsulting.png';
import consg from '../assets/consg.jpg';
import { Link } from 'react-router-dom';



function TechnologyConsultation() {
  return (
    <div className='technologyConsultationPageContainer'>
      <h2 className='technologyConsultationHeader'>Technology Consultation</h2>
      <img src={ITConsulting} alt="Technology Consultation" className="ITConsultingImg" />
    <div className='consultationSubHeaderDiv'>
      <p className='technologyConsultationSubHeader'>Your First Step To Your Next Upgrade</p>
      </div>
      <div className="technologyConsultationFadeInUponScrollCards">
      <img src={tsiwsLogo} alt="Tsiws Logo" className="consultationFooterLogo" />
    <p className='technologyConsultationCardHeader'>Introduction</p>
    <p className='technologyConsultationCardDetails'>
        At Tsiws, we understand that technology is at the core of modern businesses. 
        <br /><br /><span id='technologyConsultationCardDetailsSpanOne'>Stay ahead  with our expert technology consultation services.<br /></span> 
       <br /> We are dedicated to helping businesses navigate the complexities of today's digital landscape.<br /><br /> 
        Whether you need assistance with Strategic Planning, Digital Transformation, Technological Adoption or IT Infrastructure optimization, 
        we have the expertise to drive your business forward.
    </p>

</div>

<div className="technologyConsultationFadeInUponScrollCards">
  
    <p className='technologyConsultationCardHeader'>Consultation Service Offerings</p>
    <p className='technologyConsultationCardDetails'>
    <br /><br /><span id='technologyConsultationCardDetailsSpanSix'>Technology Adoption:<br /></span> 
        We assist businesses in adopting new technologies to stay ahead of the competition. 
        Our approach involves assessing your business needs, identifying suitable technologies, and facilitating the adoption process.
        At Tsiws, we offer a range of technology consultation services to help businesses thrive in the digital age. 
        <br /><br /><span id='technologyConsultationCardDetailsSpanOne'>Strategic Planning:<br /></span> 
        We work closely with businesses to develop technology strategies that are aligned with their goals and objectives. 
        Our approach focuses on understanding your business needs and leveraging technology to drive growth and success.
        <br /><br /><span id='technologyConsultationCardDetailsSpanTwo'>Digital Transformation:<br /></span> 
        Our experts assist businesses in leveraging technology to transform their operations and drive growth. 
        We help businesses adopt digital technologies and processes to stay competitive in today's fast-paced market.
        <br /><br /><span id='technologyConsultationCardDetailsSpanThree'>IT Infrastructure Optimization:<br /></span> 
        We specialize in optimizing IT infrastructure for efficiency and cost-effectiveness. 
        Our approach involves analyzing your existing infrastructure, identifying areas for improvement, and implementing solutions that enhance performance and reduce costs.
        <br /><br /><span id='technologyConsultationCardDetailsSpanFour'>Security Consulting:<br /></span> 
        With our expertise in security solutions, we help businesses protect themselves from cyber threats. 
        We assess your security needs, recommend solutions, and implement measures to safeguard your business.
        <br /><br /><span id='technologyConsultationCardDetailsSpanFive'>Compliance and Risk Management:<br /></span> 
        We help businesses comply with regulations and manage risks effectively. 
        Our services include risk assessment, compliance auditing, and implementation of risk management strategies.

  
    </p>
    <p className='technologyConsultationCardExpandedInfo'>
 <hr />
   
<br />Whether you're looking to revamp your IT strategy, embrace digital transformation, or enhance your cybersecurity measures, Tsiws is here to collaborate with you every step of the way.
  </p>
</div>

 







<div className="consultationProcess">

<p className='technologyConsultationCardHeaderProcess'>Our Consultation Process</p>
<img src={consg} alt="Technology Consultation" className="ITConsultingImg1" />

<p className='technologyConsultationCardExpandedInfoConsulatation'>
 <hr />
   
<br />The following covers a generaloutline of what to expect when you initiates a consultation session with us
  </p>

    <div className="consultationStep">
        <div className="stepCircle" > < p id='stepCircleNumbers'>1</p></div>
        <p className="stepDescription" id="stepDescription1">Discovery Phase:<br /> <span id='stepDescriptionSpan'> Initial meeting to understand your business goals and challenges.</span></p>
    </div>
    <div className="consultationStep">
        <div className="stepCircle" >< p id='stepCircleNumbers'>2</p></div>
        <p className="stepDescription" id="stepDescription2">Assessment Phase:<br /><span id='stepDescriptionSpan'>In-depth analysis of your current technology infrastructure and operations.</span></p>
    </div>
    <p className='technologyConsultationCardExpandedInfoConsulatation'>
 <hr />
   
<br />If after this phase you would like to commence with implementation, we move on to the next phase!
  </p>
    <div className="consultationStep">
        <div className="stepCircle" >< p id='stepCircleNumbers'>3</p></div>
        <p className="stepDescription" id="stepDescription3">Strategy Development:<br /><span id='stepDescriptionSpan'>Creation of a tailored technology strategy aligned with your business objectives.</span></p>
    </div>
    <div className="consultationStep">
        <div className="stepCircle" >< p id='stepCircleNumbers'>4</p></div>
        <p className="stepDescription" id="stepDescription4">Implementation Planning:<br /><span id='stepDescriptionSpan'>Detailed plan outlining the steps required to implement the technology strategy.</span></p>
    </div>
    <div className="consultationStep">
        <div className="stepCircle" >< p id='stepCircleNumbers'>5</p></div>
        <p className="stepDescription" id="stepDescription5">Implementation:<br /><span id='stepDescriptionSpan'>Execution of the plan, including deployment of new technologies and processes.</span></p>
    </div>
    <div className="consultationStep">
        <div className="stepCircle" >< p id='stepCircleNumbers'>6</p></div>
        <p className="stepDescription" id="stepDescription6">Monitoring and Evaluation:<br /><span id='stepDescriptionSpan'>Ongoing monitoring of the implemented solutions to ensure they are meeting your needs.</span></p>
    </div>

    <div className="consultationStep">
        <div className="stepCircle" >< p id='stepCircleNumbers'>7</p></div>
        <p className="stepDescription" id="stepDescription6">Optimization:<br /><span id='stepDescriptionSpan'>Continuous improvement of the technology infrastructure and processes based on feedback and performance metrics.</span></p>
    </div>
</div>


<div className="apiDevelopmentFadeInUponScrollCardsCard">


<p className='apiDevelopmentCardExpandedInfoCard'>Tsiws - Your Technology Solutions Provider</p>

</div>










<div className="apiDevelopmentFadeInUponScrollCards">

<img src={tsiwsLogo} alt="Tsiws Logo" className="consultationFooterLogo" />
 
  
<p className='apiDevelopmentCardExpandedInfo'>
  
At Tsiws, we are committed to helping your business navigate the complexities of the digital landscape. Our comprehensive consultation services are designed to provide strategic insights, optimize your technology infrastructure, and drive sustainable growth.<br /> <br />  Partner with us to leverage cutting-edge solutions and stay ahead in a rapidly evolving market. <br /> <br />Click the Logo below to book your appointent<br /> Or Speak with Our Ai Assistant</p>
 <div className="smartWebsiteButtonsContainer">
      <Link to="/contact" className="smartWebsiteButtons">Book Consultation</Link>
 
        <Link to="/contact" className="smartWebsiteButtons">Speak with Tsiws</Link>
        <Link to="/" className="smartWebsiteButtons">Home</Link>
      
      </div>
</div>



    </div>
  );
}

export default TechnologyConsultation;
