import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './styles/App.css';
import NavBar from './components/NavBar';
import SmartWebsites from './components/SmartWebsites';
import APIDevelopment from './components/APIDevelopment';
import TechnologyConsultation from './components/TechnologyConsultation';
import AppDevelopment from './components/AppDevelopment';
import TaskAutomation from './components/TaskAutomation';
import AIIntegration from './components/AIIntegration';
import AIAvatar from './components/AIAvatar';
import TalkWithUs from './components/TalkWithUs';
import AboutUs from './components/AboutUs';
import Blog from './components/Blog';
import Home from './components/Home';
import Footer from './components/Footer';
import DialogBox from './components/DialogBox';
import ChatInterface from './components/ChatInterface';
import MoreAboutSmartWebsites from './components/MoreAboutSmartWebsites'; // Make sure to import this component
import MoreAboutAiSystemsIntegration from './components/MoreAboutAiSystemsIntegration'; 


function App() {
  const [showChat, setShowChat] = useState(false);

  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/smart-websites" element={<SmartWebsites />} />
          <Route path="/api-development" element={<APIDevelopment />} />
          <Route path="/technology-consultation" element={<TechnologyConsultation />} />
          <Route path="/app-development" element={<AppDevelopment />} />
          <Route path="/task-automation" element={<TaskAutomation />} />
          <Route path="/ai-integration" element={<AIIntegration />} />
          <Route path="/ai-avatar" element={<AIAvatar />} />
          <Route path="/talk-with-us" element={<TalkWithUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/more-about-smart-websites" element={<MoreAboutSmartWebsites />} />
          <Route path="/More-About-Ai-Systems-Integration" element={<MoreAboutAiSystemsIntegration/>} />
        </Routes>
 
        <DialogBox onClick={() => setShowChat(true)} />
        {showChat && <ChatInterface onClose={() => setShowChat(false)} />}
        <Footer />
      </main>
    </div>
  );
}

export default App;
