import React from 'react';
import '../styles/AboutUs.css';

function AboutUs() {
  return (
    <div>
      <h2>About Tsiws</h2>
      <p>Details about us...</p>
    </div>
  );
}

export default AboutUs;
