import React from 'react';
import { Link } from 'react-router-dom';
import tsiwsLogo from '../assets/tsiws-logo.png';

import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footerContent">
        <div className="footerColumn">
          <h4>Links</h4>
          <Link to="/" className="footerLink">Home</Link>
          <Link to="/about-us" className="footerLink">About Us</Link>
          <Link to="/smart-websites" className="footerLink">Smart Websites</Link>
          <Link to="/api-development" className="footerLink">API Development</Link>
          <Link to="/technology-consultation" className="footerLink">Technology Consultation</Link>
          <Link to="/app-development" className="footerLink">App Development</Link>
          <Link to="/task-automation" className="footerLink">Task Automation</Link>
          <Link to="/ai-integration" className="footerLink">AI Integration</Link>
          <Link to="/ai-avatar" className="footerLink">AI Avatar</Link>
          <Link to="/talk-with-us" className="footerLink">Talk With Us</Link>
          <Link to="/playground" className="footerLink">Playground</Link>
          <Link to="/more-about-smart-websites" className="footerLink">More About Smart Websites</Link>
          <Link to="/more-about-ai-systems-integration" className="footerLink">More About AI Systems Integration</Link>
        </div>
        <div className="footerColumn">
          <h4>Career</h4>
          <Link to="/careers" className="footerLink">We Are Hiring</Link>
          <Link to="/internships" className="footerLink">Internships</Link>
        </div>
        <div className="footerColumn">
          <h4>Collab Opportunities</h4>
          <Link to="/partnerships" className="footerLink">Partnerships</Link>
          <Link to="/research" className="footerLink">Research Collaborations</Link>
        </div>
        <div className="footerColumn">
          <h4>Resources</h4>
          <Link to="/blog" className="footerLink">Blog</Link>
          <Link to="/faq" className="footerLink">FAQ</Link>
          <Link to="/documentation" className="footerLink">Documentation</Link>
        </div>
        <div className="footerColumn">
          <h4>Contact</h4>
          <Link to="/contact" className="footerLink">Contact Us</Link>
          <a href="mailto:support@tsiws.com" className="footerLink">
  Support <span className="emailLink">(support@tsiws.com)</span>
</a>

          </div>
        <div className="footerColumn">
          <h4>Social Media</h4>
          <div className="social-icons">
   <a href="https://x.com/tsiws_official" target="_blank" rel="noopener noreferrer">Tsiws on Twitter</a> <br />
<a href="https://instagram.com/tsiws_official" target="_blank" rel="noopener noreferrer">Tsiws on Instagram</a><br />
<a href="https://tiktok.com/@tsiws_official" target="_blank" rel="noopener noreferrer">tsiws on TikTok</a><br />
<a href="https://facebook.com/tsiws" target="_blank" rel="noopener noreferrer">Tsiws on Facebook</a><br />
<a href="https://youtube.com/@tsiws" target="_blank" rel="noopener noreferrer">Tsiws on YouTube</a><br />

  </div>        </div>
      </div>
      <div className="footerCopy">
        <p>© 2024 Tsiws. All rights reserved.</p>
      </div>
      <img src={tsiwsLogo} alt="Tsiws Logo" className="apiLogoCards" />
        </footer>
  );
}

export default Footer;
