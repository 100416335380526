// src/components/NavBar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/NavBar.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">Tsiws</Link>
      </div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
      </div>
      <ul className={`navbar-menu ${isOpen ? 'open' : ''}`}>
        <li className="navbar-item"><Link to="/smart-websites">Smart Websites</Link></li>
        <li className="navbar-item"><Link to="/api-development">API Development</Link></li>
        <li className="navbar-item"><Link to="/technology-consultation">Technology Consultation</Link></li>
        <li className="navbar-item"><Link to="/app-development">App Development</Link></li>
        <li className="navbar-item"><Link to="/task-automation">Task Automation</Link></li>
        <li className="navbar-item"><Link to="/ai-integration">AI Integration</Link></li>
        <li className="navbar-item"><Link to="/talk-with-us">Speak With Us</Link></li>
             <li className="navbar-item"><Link to="/about-us">About Us</Link></li>
             <li className="navbar-item"><Link to="/blog">Blog</Link></li>
      </ul>
    </nav>
  );
};

export default NavBar;
