// src/components/TalkWithUs.js
import React from 'react';
import '../styles/TalkWithUs.css';

function TalkWithUs() {
  return (
    <div className='talkWithUsPageContainer'>
      <h2 className='talkWithUsHeader'>Talk With Us</h2>
      <p className='talkWithUsSubHeader'>Speak with our AI Assistant for Quick Responses</p>
      <div className='talkWithUsLogoContainer'>
           </div>
      <p className='talkWithUsContactInfo'>Message, Call, or Email Us</p>
      <p className='talkWithUsSocialMedia'>There are Huge Benefits for you over on our Social Media</p>
    </div>
  );
}

export default TalkWithUs;

